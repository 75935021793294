import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const tema = createTheme({
  palette: {
    primary: {
      main: '#990537',
    },
    secondary: {
      main: '#64001d',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  overrides:{
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        background: 'linear-gradient(45deg, #530019 30%, #64001d 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(50, 50, 50, .3)',
      },
    },
    MuiTextField:{
      margin: '0 30px'
    }

  }
});

export default tema;