import { Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup, Button, TableContainer, Container } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { ConfirmationDialogRaw } from '../../componentes/confirmationdialograw';

class ListTouristSpot extends Component {
    
    emptyItem = {
        id: 0,
        title: ''
    };

    constructor(props) {
        super(props);
        this.state = {tourists: [],open:false,selected:0,item: this.emptyItem};
        this.remove = this.remove.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots`)
            .then(response => {
                console.log(response);
                if (response.ok){
                return response.json();
                }
                else{
                throw new Error("none");
                }
            })
            .then(data => this.setState({tourists: data})).catch(error=>console.log(error));
    }

    async resmove(id) {
        await fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots/tourist-spot/delete/${id}`, {
            method: 'POST',
            headers: {}
        }).then(() => {
            let updatedTourists = [...this.state.tourists].filter(i => i.id !== id);
            this.setState({tourists: updatedTourists});
        });
    }

    remove(item){
        this.setState({open:true,selected:item});
    }

    handleClose(newValue) {
        this.setState({open:false});
        if (typeof(newValue) !== 'undefined' && newValue != null) {
            this.resmove(newValue);
        }
    }

    render(){
        const {tourists} = this.state;
        console.log(tourists);
        const clientList = tourists.map(tourist => {
            return <TableRow key={tourist.id}>
                <TableCell style={{whiteSpace: 'nowrap'}}>{tourist.id}</TableCell>
                <TableCell >{tourist.title}</TableCell>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                <TableCell>
                    <ButtonGroup>
                        <Button size="sm" color="primary" component={Link} to={"/admin/tourists/" + tourist.id}>Editar</Button>
                        <Button size="sm" color="primary" component={Link} to={"/admin/touristsvalues/" + tourist.id}>Valores</Button>
                        <Button size="sm" color="primary" component={Link} to={"/admin/touristsimages/" + tourist.id}>Imagenes</Button>
                        <Button size="sm" color="primary" component={Link} to={"/admin/touristsspottab/" + tourist.id}>Tabs</Button>
                        <Button size="sm" color="danger" onClick={() => this.remove(tourist)}>Eliminar</Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
        });

        return(<Container>
            <Button color="success" component={Link} to="/admin/tourists/new">Registrar</Button>
        
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table" >
                    <TableHead>
                    <TableRow>
                        <TableCell size="small">ID</TableCell>
                        <TableCell >Lugares Turisticos</TableCell>
                        <TableCell>Opciones</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {clientList}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialogRaw
                id="ringtone-menu"
                keepMounted
                open={this.state.open}
                onClose={this.handleClose}
                value={this.state.selected.question}
                deleteid={this.state.selected.id}
                />
            </Container>
            
        );
    }

}
export default ListTouristSpot;