import { Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup, Button, TableContainer, Container, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { ConfirmationDialogRaw } from '../../../componentes/confirmationdialograw';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AuthService } from '../../../services/AuthService';

class ListTab extends Component {
    
    emptyItem = {
        orderNum: 0,
        title: ''
    };

    constructor(props) {
        super(props);
        this.state = {tabs: [],open:false,selected:0,item: this.emptyItem};
        this.remove = this.remove.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/tabs`)
            .then(response => {
                console.log(response);
                if (response.ok){
                return response.json();
                }
                else{
                throw new Error("none");
                }
            })
            .then(data => this.setState({tabs: data})).catch(error=>console.log(error));
    }

    async resmove(id) {
        await fetch(`${process.env.REACT_APP_BASE_URL}/v1/tabs/tab/${id}`, {
            method: 'DELETE',
            headers: {}
        }).then(() => {
            let updatedDistricts = [...this.state.tabs].filter(i => i.id !== id);
            this.setState({tabs: updatedDistricts});
        });
    }

    remove(item){
        this.setState({open:true,selected:item});
    }

    handleClose(newValue) {
        this.setState({open:false});
        if (typeof(newValue) !== 'undefined' && newValue != null) {
            this.resmove(newValue);
        }
    }    

    changeOptionTabOnValue = (value) => {
        if (typeof(value) !== 'undefined' && value != null && value.length > 2) {
            
            let rout = `${process.env.REACT_APP_BASE_URL}/v1/tabs/title/?title=${value}`;
            if (value == 'Todos') {
                rout = `${process.env.REACT_APP_BASE_URL}/v1/tabs`;
            }


            console.log(value);
            const authservice = AuthService;
            const currentUser = authservice.getCurrentUser;
            console.log(currentUser.accessToken);
            fetch(rout, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${currentUser.tokenType} ${currentUser.accessToken}`
                },
            })
                .then(response => response.json())
                .then(data => this.setTodosItem(data)).catch(error=>console.log(error));
        }
    };

    setTodosItem(data){
        data.push({
            orderNum: 0,
            title: 'Todos'

          });
        this.setState({tabs: data})
    };

    render(){
        const {tabs} = this.state;
        console.log(tabs);
        const tabList = tabs.map(tab => {
            return  tab.title != 'Todos' && <TableRow key={tab.id}>
                <TableCell style={{whiteSpace: 'nowrap'}}>{tab.orderNum}</TableCell>
                <TableCell >{tab.title}</TableCell>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                <TableCell>
                    <ButtonGroup>
                        <Button size="sm" color="primary" component={Link} to={"/admin/tab/" + tab.id}>Editar</Button>
                        <Button size="sm" color="danger" onClick={() => this.remove(tab)}>Eliminar</Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
        });

        return(<Container>
            <div style={{ display: 'inline-flex' }}>
                <Button color="success" component={Link} to="/admin/tab/new">Registrar</Button>
                <Autocomplete 
                    id="Tabs"
                    options={tabs}
                    getOptionLabel={(option) => option.title}
                    style={{ width: 200, position: 'absolute', right: '5%', background: 'white' }}
                    onChange={(event, newValue) => {
                        if (newValue != null) {
                            this.changeOptionTabOnValue(newValue.title);
                        }
                    }}
                    onInputChange={(event, value, reason) => {
                        if (reason === 'input') {
                            this.changeOptionTabOnValue(value);
                        }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} label="Filtro por Título" variant="outlined" />}
                />
            </div>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table" >
                    <TableHead>
                    <TableRow>
                        <TableCell size="small">Orden</TableCell>
                        <TableCell >Título</TableCell>
                        <TableCell>Opciones</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {tabList}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialogRaw
                id="delete-dialog"
                keepMounted
                open={this.state.open}
                onClose={this.handleClose}
                value={this.state.selected.question}
                deleteid={this.state.selected.id}
                />
            </Container>
            
        );
    }

}
export default ListTab;