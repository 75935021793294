import React, { useContext, createContext, useState } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
  useParams,
  useRouteMatch,
  HashRouter
} from "react-router-dom";
import Admin from "./Admin";
import Home from "./Home";
import Login from "./Login";


export default function App() {
    return (
        <Router >
            <Switch>
                <Route exact path='/' >
                    <Home />
                </Route>
                <Route path='/login'  >
                    <Login />
                </Route>
                <Route path='/admin' >
                    <Admin />
                </Route>
            </Switch>
        </Router>
    );
}

function Apps() {
    return (
        <Router >
            <Switch>
                <Route path="/">
                    <Home />
                </Route>
                <Route path="/login" >
                    <Login />
                </Route>
                <Route path="/admin">
                    <Admin />
                </Route>
            </Switch>
        </Router>
    );
}
