import { Box, Button, Container, FormGroup, Grid, Input, List, ListItem, ListItemText, Paper, Slider, TextField, Typography } from '@material-ui/core';
import React, { Component, useEffect, useState } from 'react';
import { Link, withRouter, useParams,useHistory } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import { AuthService } from '../../services/AuthService';

export default function EditTouristSpotValues(){

    
    const [data, setData] = useState([]);
    const [item, setItem] = useState({id:0,title:'...',description:'Cargando...'});
    const history = useHistory();
    let { id } = useParams();
    useEffect(function () {
        if (id !== 'new') {
            fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots/${id}`)
                .then(response => response.json())
                .then(datos => setItem(datos));
            //const data = await fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spot-preference-questions/tourist-spot-preference-question?idTouristSpot=${this.props.match.params.id}`, {
            let resStatus=0;
            fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spot-preference-questions/tourist-spot-all-preference-question?idTouristSpot=${id}`, {
                    method: 'GET',
                }
            ).then(res => {
                  resStatus = res.status;
                  return res.json()
                }
            ).then(response => {
                switch (resStatus) {
                  case 200:case 201:
                    console.log('response OK');
                    setData(response);
                    break
                  case 400:
                    if (response.code === 'ValidationFailed') {
                      // My custom error messages from the API.
                      console.log(response.fieldMessages)
                    } else {
                      console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
                    }
                    break
                  case 500: case 501: case 502: case 504: case 505:
                    console.log(response);
                    //throw new Error(response.message);
                    break
                  default:
                    console.log('unhandled')
                    break
                }
              }
            );
        }
    },[]);

    const handleChange = (event)=> {
        //console.log(index);
        console.log(event);
        const target = event.target;
        const value = Number(target.value);
  
        //index.defaultValue = value;
        //console.log(index);
      };
    const  handleSubmit =(event)=> {
        event.preventDefault();
        console.log(data);
        let resStatus=0;
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spot-preference-questions`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({idtouristspot:item.id, lista:data}),
            }
        ).then(res => {
                resStatus = res.status;
                return res.json()
            }
        ).then(response => {
            switch (resStatus) {
              case 200:case 201:
                console.log('response OK');
                history.push('/admin/tourists');
                break
              case 400:
                if (response.code === 'ValidationFailed') {
                  // My custom error messages from the API.
                    console.log(response.fieldMessages)
                } else {
                  console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
                }
                break
              case 500: case 501: case 502: case 504: case 505:
                console.log(response);
                //throw new Error(response.message);
                break
              default:
                console.log('unhandled')
                break
            }
          }
        );
        
    }

    const handleChanged = (index) =>{
        //setSelected(index);
    }

    return( <div>
            <Container maxWidth="sm">
                <h2>Valores {item.title}</h2>
                <form fullWidth onSubmit={handleSubmit}>
                    <FormGroup ></FormGroup>
                    <Grid xs={12} container spacing={4} >
                        <List>
                            {data.map((each) => (
                                <ListItem  >
                                <Grid item xs>
                                    <ListItemText primary={each.idPreferenceQuestion.question} />
                                    <CustomText defaultValue={each.value} index={each} handleChanged={handleChanged} />
                                    {/*<TextField  label="Orden" type="number" name={each.id} id={each.id} value={each.defaultValue || 50} onChange={handleChange} variant="outlined"/>*/}
                                </Grid>
                                </ListItem>
                            ))}
                        </List>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" type="submit">Save</Button>{' '}
                            <Button variant="contained" color="secondary" component={Link} to="/admin/tourists">Cancel</Button>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
}

function CustomText({ defaultValue,index ,handleChanged}) {
    const [selected, setSelected] = useState(defaultValue);
    
    const handleChange = (event) => {
      const target = event.target;
      const value = Number(target.value);
      index.value = value;

      
      const min = 0;
      const max = 100;

      console.log(min,max,value);
      setSelected(Math.max(Number(min), Math.min(Number(max), Number(value))));
      //handleChanged(index);
    };
    return (
        <Input label="Orden" type="number" name="orderNum" id="orderNum" value={selected} onChange={handleChange} variant="outlined" min="0" max="100"/>
    )
  }
