import { Box, Button, Container, FormGroup, Grid, Paper, Slider, TextField, Typography, Input } from '@material-ui/core';
import React, { Component, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AuthService } from '../../../services/AuthService';

class EditTab extends Component {
    emptyItem = {
        id: 0,
        title: '',
        code: '',
        orderNum: 0,
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            selected:{
                id:0, title:'', code:'', orderNum:0,
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {//oncreate
        
        if (this.props.match.params.id !== 'new') {
            const client = await (await fetch(`${process.env.REACT_APP_BASE_URL}/v1/tabs/tab/${this.props.match.params.id}`)).json();
            this.setState({item: client});
            console.log(client);
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;
        const authservice = AuthService;
        const currentUser = authservice.getCurrentUser;
        let resStatus = 0;
        await fetch(`${process.env.REACT_APP_BASE_URL}/v1/tabs/tab` + (item.id ? '/' + item.id : ''), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${currentUser.tokenType} ${currentUser.accessToken}`
            },
            body: JSON.stringify(item),
        }).then(res => {
            resStatus = res.status
            return res.json()
          }
        ).then(response => {
            switch (resStatus) {
              case 200:
              case 201:
                console.log('success');
                this.props.history.push('/admin/tab');
                //window.location.reload();
                break
              case 400:
                if (response.code === 'ValidationFailed') {
                  // My custom error messages from the API.
                  console.log(response.fieldMessages)
                } else {
                  console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
                }
                break
              case 500:
                console.log('error');
                console.log(response);

              default:
                console.log('unhandled')
                break
            }
          }
        );
    }

    render() {
        const {item} = this.state;
        const title = <h2>{item.id ? 'Editar Tab' : 'Registrar Tab'}</h2>;
        return <div>
            <Container maxWidth="sm">
                {title}
                <form fullWidth onSubmit={this.handleSubmit}>
                    <FormGroup ></FormGroup>
                    <Grid xs={12} container spacing={4} >
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} label="Title" type="text" name="title" id="titulo" value={item.title || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} multiline label="Code" type="text" name="code" id="codigo" value={item.code || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField   label="Orden" type="number" name="orderNum" id="orderNum" value={item.orderNum || 0} onChange={this.handleChange} variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" type="submit">Save</Button>{' '}
                            <Button variant="contained" color="secondary" component={Link} to="/admin/tab">Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    }
}

export default withRouter(EditTab);