    import authHeader from "./AuthHeader";

export const AuthService = {
    login(user, pass) {
      const requestBody = {username:user,password:pass};
      let resStatus = 0;
      return fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/signin`,
        {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody),
        }
      ).then(res => {
          resStatus = res.status
          return res.json()
        }
      ).then(response => {
          switch (resStatus) {
            case 200:
              if (response.accessToken) {
                localStorage.setItem("user", JSON.stringify(response));
              }
              break
            case 400:
              if (response.code === 'ValidationFailed') {
                // My custom error messages from the API.
                console.log(response.fieldMessages)
              } else {
                console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
              }
              break
            case 500:
              throw new Error(response.message);
            default:
              console.log('unhandled')
              break
          }
        }
      );
    },
  
    logout() {
      localStorage.removeItem("user");
    },
  
    /*register(username, email, password) {
      return axios.post(API_URL + "signup", {
        username,
        email,
        password
      });
    }*/
  
    getCurrentUser() {
      return JSON.parse(localStorage.getItem('user'));;
    },
    getAdminBoard() {
      return fetch(`${process.env.REACT_APP_BASE_URL}/v1/preference-questions`, {headers:authHeader()})
        .then(response => {
            return response;
          }, (error) => {
            if (error) {
                console.log(error);
                throw error;
            // handle error here
            }
        });
    }
  }