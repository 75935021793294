import { Box, Button, Container, FormGroup, Grid, Paper, Slider, TextField, Typography } from '@material-ui/core';
import React, { Component,useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AuthService } from '../../services/AuthService';

class EditUser extends Component {

    emptyItem = {
         id: 0,
        username : "",
        email : "",
        password : "",
        names:"",
        firstLastName:"",
        secondLastName:"",
        role : ["mod", "user"]   
    };
    
    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            msgErrorPassword :"",
            passwordError : false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {//oncreate
        if (this.props.match.params.id !== 'new') {
            const userOncreate = await (await fetch(`${process.env.REACT_APP_BASE_URL}/v1/users/user/${this.props.match.params.id}`)).json();
            this.setState({item: userOncreate});
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value; 
        this.setState({item});
    }

    handleSliderChange = (event, newValue) => {
        const target = event.target;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = newValue;
        this.setState({item});
    };

    async handleSubmit(event) {
        event.preventDefault();
        console.log(this.state);
        const {item} = this.state;
        const authservice = AuthService;
        const currentUser = authservice.getCurrentUser;

        
        if (item.password===event.target.passwordRepeat.value)
        {
            this.setState({msgErrorPassword :""});
            this.setState({passwordError : false});
            await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/signup` + (item.id ? '/' + item.id : ''), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${currentUser.tokenType} ${currentUser.accessToken}`
                },
                body: JSON.stringify(item),
            }).then(data => this.setState({item: data})).catch(error=>console.log(error));
            this.props.history.push('/admin/users');
            console.log("iguales ");
        }
        else{
            this.setState({msgErrorPassword :"Las Constraseñas no son iguales"});
            this.setState({passwordError : true});
        }
    }   
    render() {
        const {item,passwordError,msgErrorPassword} = this.state;
        const title = <h2>{item.id ? 'Editar Usuario' : 'Registrar Usuario'}</h2>;
      
        return <div>
            <Container maxWidth="sm">
                {title}
                <form fullWidth onSubmit={this.handleSubmit}>
                    <FormGroup ></FormGroup>
                    <Grid xs={12} container spacing={3} >
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} label="Nombres" type="text" name="names" id="names" value={item.names || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} label="Apellido Paterno" type="text" name="firstLastName" id="firstLastName" value={item.firstLastName || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} label="Apellido Materno" type="text" name="secondLastName" id="secondLastName" value={item.secondLastName || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}   >
                        {item.id ?
                            <TextField fullWidth xs={12} label="Email" type="text" name="email" id="email" value={item.email || ''} onChange={this.handleChange}  variant="outlined"/>:
                            <ValidatedTextField errorMsg='Ingresar Email correcto'  data={item} labelText='Email'/>}
                        </Grid>
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} label="Usuario" type="text" name="username" id="username" value={item.username || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} error={passwordError} label="Contraseña" type="password" name="password" id="password" value={item.password || ''}  onChange={this.handleChange} helperText={msgErrorPassword} variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} error={passwordError} label="Vuelva a escribir la Contraseña"  type="password" name="passwordRepeat" id="passwordRepeat"   helperText={msgErrorPassword} variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" type="submit">Save</Button>{' '}
                            <Button variant="contained" color="secondary" component={Link} to="/admin/tourists">Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    }
}

export default withRouter(EditUser);


function ValidatedTextField ({errorMsg,data,labelText}){
    const [validate, setValidate] = useState(false);
    const [msg, setMsg] = useState("");

    const handleChangeVali = (event) => {
      const target = event.target;
      const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      //Se muestra un texto a modo de ejemplo, luego va a ser un icono
      if (emailRegex.test(target.value)) {
        setValidate(false);
        setMsg("");
      } else {
        setValidate(true);
        setMsg(errorMsg);
      }  

    };
    const handleChange=(event)=>{
        data.email=event.target.value;
    }
    return (
        <TextField fullWidth xs={12} error={validate} type="text" label={labelText}   onBlur={handleChangeVali} variant="outlined" onChange={handleChange} helperText={msg}/>
    )

}
