import { makeStyles,useTheme } from '@material-ui/core/styles';
import {AppBar, Button, Container, Link, Toolbar, TextField, Typography,Tabs,Tab } from "@material-ui/core";
import ArequipaMapLogo from './logoArequipaMap.svg';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Box, Slider } from '@material-ui/core';
import React, { useState,useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GoogleMapReact from 'google-map-react';
import IconButton from '@material-ui/core/IconButton';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { render } from "react-dom";
import ImageViewer from "react-simple-image-viewer";
import { TabContext } from '@material-ui/lab';
import PropTypes from 'prop-types';
import PlayButton from './images/play.jpg';

export default function Home() {
  
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([]);
  const [districtValue, setDistrictValue] = useState([]);
  const [spotValue, setSpotValue] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [footers, setFooters] = useState([]);
  const [card, setCard] = useState({id:0,title:'...',description:'Cargando...',imageIds:[]});
  const [cards, setCards] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogVideo, setOpenDialogVideo] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [imageId, setImageId] = useState(0);
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [scroll, setScroll] = React.useState('paper');
  const drawerWidth = 240;
  
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [];
  

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openImageViewer = useCallback((index,item) => {
    /*if(item.type=="video/mp4"){*/
      setSelectedSlide(index);
      setOpenDialogVideo(true);
    /*}else{
      setCurrentImage(index);
      setIsViewerOpen(true);
    }*/
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(function () {
    console.log('render!');
    loadPreferences();
    loadFavoriteSpotPreferences();
    loadDistricts();
    loadFooter();
  },[]);
  
  const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    root: {
      display: 'flex',
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below appbar 
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    buttonAnterior: {
      cursor: 'pointer',
      position: 'absolute',
      top: '50%',
      left: 10,
      backgroundColor: '#AAA',
      '&:hover': {
        backgroundColor: '#EEE',
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
    },
    buttonSiguiente: {
      cursor: 'pointer',
      position: 'absolute',
      top: '50%',
      right: 10,
      backgroundColor: '#AAA',
      '&:hover': {
        backgroundColor: '#EEE',
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(8),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 1.5),
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    /// cards
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(8),
      elevation:10
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '40.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    cardTextShort:{
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 3,
        overflow: "hidden"
    },
    imageContainer:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    
    imageZContainer:{
      height:'70vh',
      width: '100%',
      textAlign: 'center',
      margin: '1em 0'
    },
    imageZ:{
      verticalAlign: 'middle',
      maxHeight: '100%',
      maxWidth: '100%',
    },
    imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
    /// cards
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
    darkDialog:{
      '& .MuiPaper-root': {
        backgroundColor: '#111'
      }

    }
  }));
  const classes = useStyles();
  const theme = useTheme();

  const loadPreferences = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/preference-questions`)
            .then(response => response.json())
            .then(datos => setData(datos));
  };

  const loadDistricts = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/districts/sort`)
            .then(response => response.json())
            .then( (datos) => {
              datos.push({
                id: '',
                name: 'Todos',
                description: ''
              });
              setDistricts(datos);
              console.log(datos);
            } );
  };

  const loadFooter = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/footer`)
          .then(response => response.json())
          .then(datos => setFooters(datos));
  };

  const changeOptionSpotOnValue = (value) => {
    if (typeof(value) !== 'undefined' && value != null && value.length > 2) {
      console.log(value);
      fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots/name/?name=${value}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())
        .then(datos => setCards(datos.listPreferenceSimilary));
    }
  };

  const loadTabs = (spotId) => {
    console.log("spotId: " + spotId);
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots-tabs/spot/${spotId}/notall`)
            .then(response => response.json())
            .then(datos => setTabs(datos));
  };

  const handleDistrictChange = (event,newValue) => {
    setDistrictValue(newValue);
    if (typeof(newValue) !== 'undefined' && newValue != null) {
      let rout = `${process.env.REACT_APP_BASE_URL}/v1/tourist-spots/district/${newValue.id}`;
      if (newValue.name == 'Todos') {
        rout = `${process.env.REACT_APP_BASE_URL}/v1/tourist-spots/all`;
      }
      fetch(rout, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())
        .then(datos => setCards(datos.listPreferenceSimilary));
    }
  };

  const loadFavoriteSpotPreferences = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots/favorite`)
            .then(response => response.json())
            .then(datos => setCards(datos.listPreferenceSimilary));
  };

  const handleClickOpen = () => {
    console.log('open Dialog');
    setOpen(prevOpen=> !prevOpen);
  };

  const handleSliderChange = (event,newValue) => {
    setDistrictValue("");
    setSpotValue("");
    console.log(data);
    console.log('Llamar recomendaciones');
    console.log(event);
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spot-preferences`, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          numPreferenceReturn: 2,
          listQuestionValues:data
        }
      ),
    })
      .then(response => response.json())
      .then(datos => setCards(datos.listPreferenceSimilary));
  };

  const remove = (item) => {
    setCard(item);
    loadTabs(item.id);
    setValue(0);
    setOpenDialog(true);
  }
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseDialogVideo = () => {
    setOpenDialogVideo(false);
  };

  const handleOpenGoogleMaps = () => {
    window.open("https://maps.google.com?q="+card.latitude+","+card.longitude );
  };

  const defaultProps = {
    center: {
      lat: -16.4034517,
      lng: -71.5390996
    },
    zoom: 11
  };
  
  const onMapChildClick = (key, childProps) => {
    setCard(childProps.item);
    setOpenDialog(true);
  }

  return (
    <div className={classes.root}>
    <AppBar position="static" color="default" elevation={0} className={classes.appBar}
     position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
    >
      <Toolbar className={classes.toolbar}>
        <img src={ArequipaMapLogo} alt="ArequipaMap Logo" width="40em" />
        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
          ArequipaMap
        </Typography>
        <nav>
          <Grid style={{ display: 'inline-flex', paddingTop: '6px', paddingBottom: '6px' }}>
            <Autocomplete
              id="Lugares"
              value={spotValue}
              options={cards}
              getOptionLabel={(option) => option.title}
              style={{ width: 250 }}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  changeOptionSpotOnValue(newValue.title);
                }
              }}
              onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                  changeOptionSpotOnValue(value);
                }
              }}
              renderInput={(params) =>
                <TextField {...params} label="Filtro por Lugares" variant="outlined" />}
              />
            <Autocomplete
              id="Distritos"
              value={districtValue}
              options={districts}
              getOptionLabel={(option) => option.name}
              style={{ width: 250 }}
              onChange={handleDistrictChange}
              renderInput={(params) =>
                <TextField {...params} label="Filtro por distrito" variant="outlined" />}
              />
          </Grid>
          {/*<Link variant="button" color="textPrimary" href="#" className={classes.link}>
            Inicio
          </Link>
          <Link variant="button" color="textPrimary" href="#" className={classes.link}>
            Mapa
          </Link>*/}
        </nav>
        <Button href="#" color="primary" variant="outlined" className={clsx(open && classes.hide)} onClick={handleClickOpen}>
          Preferencias
        </Button>
      </Toolbar>
    </AppBar>
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
    >
      <div style={{ height: '55vh', width: `calc(100% - ${drawerWidth}px)` }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBYxbucbGEXjhhLheJRLuwLv3av-GsCNVo' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onChildClick={onMapChildClick}
        >
          {
            cards.map((card) => (
                    <CustomMarker
                        lat={ card.latitude}
                        lng={ card.longitude}
                        item={card}
                    />
                )
            )
          }
        </GoogleMapReact>
        <Container className={classes.cardGrid} >
          <Grid container spacing={1}>
            { cards.length == 0? <div style={{width: '100%',textAlign: 'center',borderColor: 'crimson',borderWidth: 'medium',borderStyle: 'solid',borderRadius: '6px',padding: '3px'}}  >No se han encontrado destino Turístico</div> :
              cards.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia className={classes.cardMedia} image={card.imageIds.filter(c => c.type!="video/mp4").length>0 ?`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file/${card.imageIds.filter(c => c.type!="video/mp4")[0].id}`:"https://source.unsplash.com/random"} title={card.title} onClick={() => remove(card)} 
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.title}
                    </Typography>
                    <Box
                        component="div"
                        classes={{root: classes.cardTextShort}}
                      >
                        {card.description}
                      </Box>
                  </CardContent>
                  <CardActions>
                    <Button variant="contained" color="primary" onClick={() => remove(card)} >
                      Ver Mas
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '4vh', }} >
          <Container component="main"  maxWidth="md"  >
            <Typography variant="h5" component="h2" gutterBottom>
              {footers.length>0?
              footers[0].systemConfig.value : 'Loading'
            }
            </Typography>
            <Grid container  style={{paddingBottom:10}} justifyContent="center" spacing={4} >
              {footers.map( (item) =>(
                <a item  href={`${item.link}`} target="_blank">
                  <img height="50" src={`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file/${item.id}`} alt="Unsa"/>
                </a>
              ))}
            </Grid >
          </Container>
        </Box>
      </div>
      
      <Drawer
        className={classes.drawer} variant="persistent" anchor="right" open={open} classes={{paper: classes.drawerPaper,}} >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleClickOpen}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <Link variant="button" color="textPrimary" href="#" onClick={handleClickOpen}>
            Preferencias
          </Link>
        </div>
        <List>
          {data.map((each,index) => (
            <ListItem  >
              <Grid item xs>
                <ListItemText primary={each.question} />
                <CustomSlider defaultValue={each.value || 50} onChangeCommitted={handleSliderChange} index={each} label='name' />
              </Grid>
            </ListItem>
          ))}
        </List>
        <Dialog open={openDialog} onClose={handleCloseDialog} scroll={scroll} aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description">
          <DialogTitle id="scroll-dialog-title">{card.title}</DialogTitle>
          <DialogContent dividers={scroll === 'paper'} height={'90vh'}>
            <CustomDialogContent card={card} classes={classes} openImageViewer={openImageViewer} />
            {tabs.length == 0 &&
              <DialogContentText id="scroll-dialog-description" tabIndex={-1} >
                {card.description}
              </DialogContentText>
            }
            {tabs.length > 0 &&
              <Container maxWidth="sm">
                <TabContext>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                      {tabs.map( (each, index) => (
                        <Tab label={each.tab.title} index={index} {...a11yProps(index)} wrapped />
                      ))}
                    </Tabs>
                  </Box>
                  {tabs.map( (each, index) => (    
                    <TabPanel value={value} index={index}>
                      <Grid   >
                        {each.contents}
                      </Grid>
                    </TabPanel>
                  ))}
                </Box>
                </TabContext>
              </Container>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOpenGoogleMaps} variant="contained" color="primary">
              Ir a Google Maps
            </Button>
            <Button onClick={handleCloseDialog} variant="contained" color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog  maxWidth={'xl'} fullWidth={true} className={classes.darkDialog} open={openDialogVideo} onClose={handleCloseDialogVideo} scroll={scroll} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
          <DialogTitle id="scroll-dialog-title">{card.title}</DialogTitle>
          <DialogContent dividers={scroll === 'paper'} >
            <CustomDialogVideo card={card} classes={classes} selectedIndex={selectedSlide}/>
            <IconButton aria-label="Anterior" className={classes.buttonAnterior} onClick={()=>{if(selectedSlide>0){setSelectedSlide(selectedSlide-1);}}}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton aria-label="Siguiente" className={classes.buttonSiguiente} onClick={()=>{if(selectedSlide<card.imageIds.length-1)setSelectedSlide(selectedSlide+1)}}>
              <ChevronRightIcon />
            </IconButton>
          </DialogContent>
          <DialogActions>
            
            <Button onClick={handleCloseDialogVideo} variant="contained" color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

      </Drawer>
    </main>
      {
              card.imageIds.length>0?card.imageIds.map((item, index) => (
                images.push(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file/${item.id}`)
              )):
              <Grid />
            }
      {isViewerOpen && (
        <div style={{zIndex: '1400' }}>
              <ImageViewer 
                src={images}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)"
                }}
                closeOnClickOutside={true}
              />
        </div>
            )}
            
    </div>
  );
}

function CustomSlider({ defaultValue, onChangeCommitted,index,label }) {
  const handleSliderChange = (event,newValue) => {
    //console.log(index);
    index.defaultValue = newValue;
    //console.log(index);
  };
  return (
    <Slider defaultValue ={defaultValue} onChangeCommitted={onChangeCommitted} onChange={handleSliderChange}
        valueLabelDisplay="auto" step={10} marks min={0} max={100}
    />
  )
}

function CustomDialogContent({ card,classes,openImageViewer}) {  
  return card.imageIds.length>0?(
    <div className={classes.imageContainer} >
        <ImageList className={classes.imageList} cols={2.5}>
            {card.imageIds.length>0?card.imageIds.map((item, index) => (
                <ImageListItem key={item}>
                  <img 
                    src={item.type=="video/mp4"?PlayButton:`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file/${item.id}`}
                    onClick={() => openImageViewer(index,item)}
                    width="300"
                    key={index}
                    style={{ margin: "2px",cursor:'pointer' }}
                    alt=""
                  />
                </ImageListItem>
                )
              ):
              <Grid />
            }
        </ImageList>
    </div>
  ):(<Grid />)
}

function CustomDialogVideo({ card,classes,selectedIndex}) {  
  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    if(vidRef.current.paused){
      vidRef.current.play();
    }else{
      vidRef.current.pause();
    }
  }
  const slides = [];
  card.imageIds.map((item, index) => (
    slides.push(
      item.type=="video/mp4"?
      <video ref={vidRef} autoplay="true" style={{objectFit: 'cover',height:'100%'}} onClick={handlePlayVideo} component="iframe" src={`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file/${item.id}`} alt="imagen" />:
      <img className={classes.imageZ} 
        src={`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file/${item.id}`}
        component="img"
        
        xs={{backgroundSize: 'cover'}}
      />
    )
  ));
  return card.imageIds.length>0?(
    <div className={classes.imageZContainer} >
      <span class="helper"></span>
      {slides[selectedIndex]}
    </div>
  ):(<Grid />)
}

function CustomMarker ({item}){ 
    return (
        <div>
            <img src={ArequipaMapLogo} alt="ArequipaMap Logo" width="36em" />
        </div>
    )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}