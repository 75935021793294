import { Box, Button, Container, FormGroup, Grid, Paper, Slider, TextField, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';

class EditarPreferencia extends Component {

    emptyItem = {
        id: 0,
        question: '',
        orderNum: 0,
        defaultValue:50
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {//oncreate
        if (this.props.match.params.id !== 'new') {
            const client = await (await fetch(`${process.env.REACT_APP_BASE_URL}/v1/preference-questions/${this.props.match.params.id}`)).json();
            this.setState({item: client});
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    handleSliderChange = (event, newValue) => {
        const target = event.target;
        const name = target.name;
        let item = {...this.state.item};
        item['defaultValue'] = newValue;
        this.setState({item});
    };

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;

        await fetch(`${process.env.REACT_APP_BASE_URL}/v1/preference-questions/preference-question` + (item.id ? '/' + item.id : ''), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item),
        });
        this.props.history.push('/admin/preferences');
    }

    render() {
        const {item} = this.state;
        const title = <h2>{item.id ? 'Editar Preferencia' : 'Registrar Preferencia'}</h2>;

        return <div>
            <Container maxWidth="sm">
                {title}
                <form fullWidth onSubmit={this.handleSubmit}>
                    <FormGroup ></FormGroup>
                    <Grid xs={12} container spacing={4}>
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} label="Pregunta" type="text" name="question" id="question" value={item.question || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField  label="Orden" type="number" name="orderNum" id="orderNum" value={item.orderNum || 0} onChange={this.handleChange} variant="outlined"/>
                        </Grid>
                        <Grid item xs>
                            <Typography id="discrete-slider-restrict" gutterBottom>
                                Valor por Defecto
                            </Typography>
                            <Slider name="defaultValue" id="defaultValue" value={item.defaultValue || 0} onChange={this.handleSliderChange} defaultValue={50} step={10} valueLabelDisplay="on" />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" type="submit">Save</Button>{' '}
                            <Button variant="contained" color="secondary" component={Link} to="/admin/preferences">Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    }
}

export default withRouter(EditarPreferencia);