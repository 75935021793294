import { Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup, Button, TableContainer, Container } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { ConfirmationDialogRaw } from '../../componentes/confirmationdialograw';

class ListUser extends Component {
    
    emptyItem = {
        id: 0,
        fullName: '',
        username: 0,
        state:1
    };

    constructor(props) {
        super(props);
        this.state = {users: [],open:false,selected:0,item: this.emptyItem};
        this.remove = this.remove.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/users`)
            .then(response => {
                console.log(response);
                if (response.ok){
                return response.json();
                }
                else{
                throw new Error("none");
                }
            })
            .then(data => this.setState({users: data})).catch(error=>console.log(error));
    }

    async resmove(id) {
        console.log(id);    
        await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/eliminar/${id}`, {
            method: 'POST',
            headers: {}
        }).then(() => {
            let updatedClients = [...this.state.users].filter(i => i.id !== id);
            this.setState({users: updatedClients});
        }) ;
    }

    remove(item){
        this.setState({open:true,selected:item});
    }

    handleClose(newValue) {
        this.setState({open:false});
        if (typeof(newValue) !== 'undefined' && newValue != null) {
            this.resmove(newValue);
        }
      }

    render(){
        const {users} = this.state;
        console.log(users);
        const usersList = users.map(user => {
            return <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell style={{whiteSpace: 'nowrap'}}>{user.fullName}</TableCell>
                <TableCell align="left" >{user.username}</TableCell>
                <TableCell align="right">
                    {(()=>{
                         switch (user.state){
                        case 0: return "Inactivo";
                        case 1: return "Activo";
                        case 2: return "Eliminado";
                        default: return "No tiene Estado";
                }
                })()}</TableCell>
                <TableCell>
                    <ButtonGroup>
                        <Button size="sm" color="primary" component={Link} to={"/admin/users/" + user.id}>Editar</Button>
                        <Button size="sm" color="danger" onClick={() => this.remove(user)}>Eliminar</Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
        });

        return(<Container>
            <Button  color="success" component={Link} to="/admin/users/new">Registrar</Button>
        
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table" >
                    <TableHead>
                    <TableRow>
                        <TableCell size="small">ID</TableCell>
                        <TableCell>Nombres y Apellidos</TableCell>
                        <TableCell align="left" size="small">Usuarios</TableCell>
                        <TableCell align="right" size="small">Activos</TableCell>
                        <TableCell>Opciones</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {usersList}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialogRaw
                id="ringtone-menu"
                keepMounted
                open={this.state.open}
                onClose={this.handleClose}
                value={this.state.selected.question}
                deleteid={this.state.selected.id}
                />
            </Container>
            
        );
    }

}
export default ListUser;