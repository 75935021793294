import { Box, Button, Container, FormGroup, Grid, Input, List, ListItem, ListItemText, Paper, Slider, TextField, Typography } from '@material-ui/core';
import React, { Component, useEffect, useState } from 'react';
import { Link, withRouter, useParams,useHistory } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import { AuthService } from '../../services/AuthService';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import CardMedia from '@material-ui/core/CardMedia';
import PlayImage from '../../images/play.jpg';

export default function EditTouristSpotImages(){

    
    const [data, setData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewVideo, setPreviewVideo] = useState(null);
    const [item, setItem] = useState({id:0,title:'...',description:'Cargando...'});
    const history = useHistory();
    
    
    let { id } = useParams();
    useEffect(function () {
        if (id !== 'new') {
            fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots/${id}`)
                .then(response => response.json())
                .then(datos => setItem(datos));
            //const data = await fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spot-preference-questions/tourist-spot-preference-question?idTouristSpot=${this.props.match.params.id}`, {
            loadFiles(id);
            /*let resStatus=0;
            fetch(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/touristSpot/${id}`, {
                    method: 'GET',
                }
            ).then(res => {
                  resStatus = res.status;
                  return res.json()
                }
            ).then(response => {
                switch (resStatus) {
                  case 200:case 201:
                    console.log('response OK');
                    setData(response);
                    break
                  case 400:
                    if (response.code === 'ValidationFailed') {
                      // My custom error messages from the API.
                      console.log(response.fieldMessages)
                    } else {
                      console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
                    }
                    break
                  case 500: case 501: case 502: case 504: case 505:
                    console.log(response);
                    //throw new Error(response.message);
                    break
                  default:
                    console.log('unhandled')
                    break
                }
              }
            );*/
        }
    },[]);

    const loadFiles = (id) => {
      let resStatus=0;
      fetch(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/touristSpot/${id}`, {
              method: 'GET',
          }
      ).then(res => {
            resStatus = res.status;
            return res.json()
          }
      ).then(response => {
          switch (resStatus) {
            case 200:case 201:
              console.log('response OK');
              setData(response);
              break
            case 400:
              if (response.code === 'ValidationFailed') {
                // My custom error messages from the API.
                console.log(response.fieldMessages)
              } else {
                console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
              }
              break
            case 500: case 501: case 502: case 504: case 505:
              console.log(response);
              //throw new Error(response.message);
              break
            default:
              console.log('unhandled')
              break
          }
        }
      );

    };
    
    const onFileChange = event => {
      if(event.target.files==null || event.target.files.length==0) return;
      console.log(event.target.files[0].type);
        if(event.target.files[0].type=='image/png' || event.target.files[0].type=='image/jpg' || event.target.files[0].type=='image/jpeg'){
          setSelectedFile(event.target.files[0]);
          setPreviewVideo(null);
          setPreviewImage(URL.createObjectURL(event.target.files[0]));
        }else if(event.target.files[0].type=='video/mp4'){
          setSelectedFile(event.target.files[0]);
          setPreviewImage(null);
          setPreviewVideo(URL.createObjectURL(event.target.files[0]));
        }else{
            event.target.value = "";
        }
    };
    
    const onFileUpload = () => { 
        if(selectedFile==null) return;
        let resStatus=0;
        const formData = new FormData(); 
        
        // Update the formData object 
        formData.append( 
          "file", 
          selectedFile, 
          selectedFile.name 
        ); 

        // Details of the uploaded file 
        console.log(selectedFile); 
        
        // Request made to the backend api 
        // Send formData object 
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file?spotId=${id}&origin=web`, {
                method: 'POST',
                body:formData
            }
        ).then(res => {
              resStatus = res.status;
              return res.json()
            }
        ).then(response => {
            switch (resStatus) {
              case 200:case 201:
                console.log('response OK');
                window.location.reload();
                break
              case 400:
                if (response.code === 'ValidationFailed') {
                  // My custom error messages from the API.
                  console.log(response.fieldMessages)
                } else {
                  console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
                }
                break
              case 500: case 501: case 502: case 504: case 505:
                console.log(response);
                //throw new Error(response.message);
                break
              default:
                console.log('unhandled')
                break
            }
          }
        );
        
    };     

    const remove = (item) =>{
      if (typeof(item) !== 'undefined' && item != null) {
        console.log("Intenta borrar: " + item.id);
        
          fetch(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/${item.id}`, {
              method: 'DELETE',
              headers: {}
          }).then(() => {
            loadFiles(id);
          });
      }
  }

    return( <div>
            <Container maxWidth="sm">
                <h2>Imagenes {item.title}</h2>
                    <FormGroup ></FormGroup>
                    <Grid xs={12} container spacing={4} >
                        <Box component="span" m={1}>
                            <CardMedia height="300" component={previewImage==null?"iframe":"img"} image={previewImage} src={previewVideo} alt="imagen"/>
                        </Box>
                        <Grid item xs={12}>
                            <Button variant="contained" component="label"> <Input type="file" accept="image/png, image/jpeg, video/mp4" onChange={onFileChange} /></Button>
                            <Button onClick={onFileUpload}>Upload!</Button> 
                        </Grid>
                        
                        <ImageList rowHeight={160} cols={3}>
                            {data.map((item) => (
                              <ImageListItem key={item.img} cols={item.cols || 1}>
                                <img src={item.type=="video/mp4"?PlayImage:`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file/${item.id}`} alt={item.title}/>
                                <Button style={{zIndex: '5', position: 'absolute', top: 0, left: 0 }} onClick={() => remove(item)} size="sm" color="danger" >Eliminar</Button>
                              </ImageListItem>
                            ))}
                        </ImageList>
                        
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" component={Link} to="/admin/tourists">Cancel</Button>
                        </Grid>
                        
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
            </Container>
        </div>
    );
}

function CustomText({ defaultValue,index ,handleChanged}) {
    const [selected, setSelected] = useState(defaultValue);
    
    const handleChange = (event) => {
      const target = event.target;
      const value = Number(target.value);
      index.value = value;
      setSelected(value);
      //handleChanged(index);
    };
    return (
        <Input label="Orden" type="number" name="orderNum" id="orderNum" value={selected} onChange={handleChange} variant="outlined"/>
    )
  }