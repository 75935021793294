import { Button, Container, FormGroup, Grid, Input, List, ListItem, ListItemText, Paper, Slider, TextField, Box, Typography, Tabs, Tab, Checkbox } from '@material-ui/core';
import React, { Component, useEffect, useState } from 'react';
import { Link, withRouter, useParams,useHistory } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import { AuthService } from '../../services/AuthService';
import PropTypes from 'prop-types';
import { TabContext } from '@material-ui/lab';

export default function EditTouristSpotTabs(){
    
    const [data, setData] = useState([]);
    const [item, setItem] = useState({id:0,title:'...',description:'Cargando...'});
    const [value, setValue] = useState(0);
    const [title, setTitle] = useState("");
    const [indice, setIndice] = useState(0);
    const history = useHistory();

    const handleChange = (event, newValue) => {
      setIndice(newValue);
      setValue(newValue);
    };
    
    const handleChanged = (event) =>{
      const target = event.target;
      const value = target.value;
      const name = target.name;
      if (target.name == "active") {
        if (event.target.checked) {
          data[indice].state = 1;
        } else data[indice].state = 0;
        console.log(data[indice]);
      }else if (target.name == "content") {
        data[indice].contents = value;
      }
  }

    let { id } = useParams();

    useEffect(function () {
      console.log('pdrt');
        if (id !== 'new') {
            fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots-tabs/spot/${id}`)
                .then(response => response.json())
                .then(datos => (setData(datos),
                  setTitle(datos[0].spotTitle)
                ));
            let resStatus=0;
            
        }
    },[]);
    const onSave = (event) =>{
        console.log(data);
      event.preventDefault();
      let resStatus=0;
      data.map((each, index) => (
        
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots-tabs/spot-tab/${each.id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(each),
            }
        ).then(res => {
                resStatus = res.status;
                return res.json()
            }
        ).then(response => {
            switch (resStatus) {
              case 200:case 201:
                console.log('response OK');
                history.push('/admin/tourists');
                break
              case 400:
                if (response.code === 'ValidationFailed') {
                  // My custom error messages from the API.
                    console.log(response.fieldMessages)
                } else {
                  console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
                }
                break
              case 500: case 501: case 502: case 504: case 505:
                console.log(response);
                //throw new Error(response.message);
                break
              default:
                console.log('unhandled')
                break
            }
          }
        )
      ));
      
  }

  
    return (
      <Container maxWidth="sm">
        <h2>Tabs de {title}</h2>
        <TabContext>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {data.map( (each, index) => (
                <Tab label={each.tab.title} index={index} {...a11yProps(index)} />

              ))}
            </Tabs>
          </Box>
          {data.map( (each, index) => (    
            <TabPanel value={value} index={index}>
              <Grid>
                <CustomCheckbox defaultValue={each.state} index={each} handleChanged={handleChanged} /> Está activo
              </Grid>
              <Grid   >
                <CustomText defaultValue={each.contents} index={each} handleChanged={handleChanged} />
              </Grid>
            </TabPanel>

          ))}
        </Box>
          
        </TabContext>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={onSave} >Save</Button>{' '}
          <Button variant="contained" color="secondary" component={Link} to="/admin/tourists">Cancel</Button>
        </Grid>
      </Container>
    );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomText({ defaultValue, index ,handleChanged}) {
  const [selected, setSelected] = useState(defaultValue);
  
  const handleChange = (event) => {
      const target = event.target; 
      console.log(target.value);
      setSelected(target.value);
      index.contents=target.value;
    };
  
  return (
      <Input fullWidth label="Contenido" multiline rows={4} type="text" name="content" value={selected} onChange={handleChange} />
  )
}

function CustomCheckbox({ defaultValue,index ,handleChanged}) {
  const [selected, setSelected] = useState(defaultValue==0?false:true);
  
  const handleChange = (event) => {
      const target = event.target;
      console.log(target.checked);
      setSelected(target.checked);
      index.state=target.checked?1:0;
    };
    
  return (
    <Checkbox  checked={selected} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
  )
}