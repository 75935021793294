import { Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup, Button, TableContainer, Container } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { ConfirmationDialogRaw } from '../../componentes/confirmationdialograw';

class ListaPreferencias extends Component {
    
    emptyItem = {
        id: 0,
        question: '',
        orderNum: 0,
        defaultValue:50
    };

    constructor(props) {
        super(props);
        this.state = {clients: [],open:false,selected:0,item: this.emptyItem};
        this.remove = this.remove.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/preference-questions`)
            .then(response => {
                console.log(response);
                if (response.ok){
                return response.json();
                }
                else{
                throw new Error("none");
                }
            })
            .then(data => this.setState({clients: data})).catch(error=>console.log(error));
    }

    async resmove(id) {
        await fetch(`${process.env.REACT_APP_BASE_URL}/v1/preference-questions/eliminar/${id}`, {
            method: 'POST',
            headers: {}
        }).then(() => {
            let updatedClients = [...this.state.clients].filter(i => i.id !== id);
            this.setState({clients: updatedClients});
        });
    }

    remove(item){
        this.setState({open:true,selected:item});
    }

    handleClose(newValue) {
        this.setState({open:false});
        if (typeof(newValue) !== 'undefined' && newValue != null) {
            this.resmove(newValue);
        }
      }

    render(){
        const {clients} = this.state;
        console.log(clients);
        const clientList = clients.map(client => {
            return <TableRow key={client.id}>
                <TableCell style={{whiteSpace: 'nowrap'}}>{client.id}</TableCell>
                <TableCell>{client.question}</TableCell>
                <TableCell align="right">{client.orderNum}</TableCell>
                <TableCell align="right">{client.defaultValue}</TableCell>
                <TableCell>
                    <ButtonGroup>
                        <Button size="sm" color="primary" component={Link} to={"/admin/preferences/" + client.id}>Editar</Button>
                        <Button size="sm" color="danger" onClick={() => this.remove(client)}>Eliminar</Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
        });

        return(<Container>
            <Button  color="success" component={Link} to="/admin/preferences/new">Registrar</Button>
        
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table" >
                    <TableHead>
                    <TableRow>
                        <TableCell size="small">ID</TableCell>
                        <TableCell>Pregunta</TableCell>
                        <TableCell align="right" size="small">Orden</TableCell>
                        <TableCell align="right" size="small">Valor por Defecto</TableCell>
                        <TableCell>Opciones</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {clientList}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialogRaw
                id="ringtone-menu"
                keepMounted
                open={this.state.open}
                onClose={this.handleClose}
                value={this.state.selected.question}
                deleteid={this.state.selected.id}
                />
            </Container>
            
        );
    }

}
export default ListaPreferencias;