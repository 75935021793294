import { Box, Button, Container, FormGroup, Grid, Paper, Slider, TextField, Typography, Input } from '@material-ui/core';
import React, { Component, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import { AuthService } from '../../services/AuthService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CardMedia from '@material-ui/core/CardMedia';
import PlayImage from '../../images/play.jpg';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class EditSystemConfig extends Component {

    emptyItem = {
        id: 0,
        value: '',
        tag: 0,
        status:'',
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            selected:{
                id:'', name:'', description:''
            },
            selectedFile: null,
            previewImage: null,
            filestorage: [],
            openedDialogLink: false,
            linkItem:{
                id:0, link:''
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLinkChange = this.handleLinkChange.bind(this);
    }

    async componentDidMount() {//oncreate
        
        if (this.props.match.params.id !== 'new') {
            const client = await (await fetch(`${process.env.REACT_APP_BASE_URL}/v1/system-config/system-config/${this.props.match.params.id}`)).json();
            this.setState({item: client});

            if (client.tag == process.env.REACT_APP_CONFIGTAG_FOOTER) {
                this.loadFooter();
                /*fetch(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/footer`)
                .then(response => response.json())
                .then(datos => this.setState({filestorage : datos}));*/
            }
        }
    }

    loadFooter = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/footer`)
                .then(response => response.json())
                .then(datos => (this.setState({filestorage : datos}), console.log(datos)));
    }
    
    onFileChange = (event) => {
      if(event.target.files==null || event.target.files.length==0) return;
      console.log(event.target.files[0].type);
        if(event.target.files[0].type=='image/png' || event.target.files[0].type=='image/jpg' || event.target.files[0].type=='image/jpeg'){
            this.setState({selectedFile: event.target.files[0]});
            this.setState({previewImage: URL.createObjectURL(event.target.files[0])});
        }else{
            event.target.value = "";
        }
    }

    onFileUpload = () => { 
        if(this.state.selectedFile==null) return;
        let resStatus=0;
        const formData = new FormData(); 
        
        // Update the formData object 
        formData.append( 
          "file", 
          this.state.selectedFile, 
          this.state.selectedFile.name 
        ); 

        // Details of the uploaded file 
        console.log(this.state.selectedFile); 
        
        // Request made to the backend api 
        // Send formData object 
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file?configTag=${process.env.REACT_APP_CONFIGTAG_FOOTER}&origin=web`, {
                method: 'POST',
                body:formData
            }
        ).then(res => {
              resStatus = res.status;
              return res.json()
            }
        ).then(response => {
            switch (resStatus) {
              case 200:case 201:
                console.log('response OK');
                window.location.reload();
                break
              case 400:
                if (response.code === 'ValidationFailed') {
                  // My custom error messages from the API.
                  console.log(response.fieldMessages)
                } else {
                  console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
                }
                break
              case 500: case 501: case 502: case 504: case 505:
                console.log(response);
                //throw new Error(response.message);
                break
              default:
                console.log('unhandled')
                break
            }
          }
        );
        
    }

    async remove(item){
      console.log(item);
        if (typeof(item) !== 'undefined' && item != null) {
            await fetch(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/${item.id}`, {
                method: 'DELETE',
                headers: {}
            }).then(() => {
                this.loadFooter();
            });
        }
    }

    openDialogLink(item){
      console.log(item);
      this.setState({linkItem:{ id:item.id, link:item.link }});
      this.setState({openedDialogLink : true})
    }

    async updateLink(){
      console.log('updateLink');
      console.log(JSON.stringify(this.state.linkItem));
      if (typeof(this.state.linkItem) !== 'undefined' && this.state.linkItem != null) {
        await fetch(`${process.env.REACT_APP_BASE_URL}/v1/file-storage/${this.state.linkItem.id}`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(this.state.linkItem),
        }).then(() => {
            this.loadFooter();
        });
      }
      this.setState({openedDialogLink : false});
    }

    handleCloseDialogLink(){
      console.log('handleCloseDialogLink');
      this.setState({openedDialogLink : false});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const favorite = target.favorite;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    handleLinkChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let linkItem = this.state.linkItem;
        linkItem[name] = value;
        this.setState({linkItem});
        console.log(linkItem);
    }

    handleSliderChange = (event, newValue) => {
        const target = event.target;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = newValue;
        this.setState({item});
    };

    handleDistrictChange = (event, newValue) => {
      const target = event.target;
      const name = target.name;
      let item = {...this.state.item};
      item['district'] = newValue;
      this.setState({item});
  };

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;
        const authservice = AuthService;
        const currentUser = authservice.getCurrentUser;
        let resStatus = 0;
        await fetch(`${process.env.REACT_APP_BASE_URL}/v1/system-config/system-config` + (item.id ? '/' + item.id : ''), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${currentUser.tokenType} ${currentUser.accessToken}`
            },
            body: JSON.stringify(item),
        }).then(res => {
            resStatus = res.status
            return res.json()
          }
        ).then(response => {
            switch (resStatus) {
              case 200,201:
                console.log('success');
                this.props.history.push('/admin/config');
                //window.location.reload();
                break
              case 400:
                if (response.code === 'ValidationFailed') {
                  // My custom error messages from the API.
                  console.log(response.fieldMessages)
                } else {
                  console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
                }
                break
              case 500:
                console.log('error');
                console.log(response);

              default:
                console.log('unhandled')
                break
            }
          }
        );
    }

    render() {
        const {item} = this.state;
        const title = <h2>{item.id ? 'Editar Configuracion' : 'Registrar Configuración'}</h2>;
        return <div>
            <Container maxWidth="sm">
                {title}
                <form fullWidth onSubmit={this.handleSubmit}>
                    <FormGroup ></FormGroup>
                    <Grid xs={12} container spacing={4} >
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} disabled={item.id? true:false} label="Tag" type="text" name="tag" id="tag" value={item.tag || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} multiline label="Valor" type="text" name="value" id="value" value={item.value || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" type="submit">Save</Button>{' '}
                            <Button variant="contained" color="secondary" component={Link} to="/admin/config">Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
            {item.tag == process.env.REACT_APP_CONFIGTAG_FOOTER &&
                <Container maxWidth="sm">
                <h2>Imagenes de Auspiciadores</h2>
                    <FormGroup ></FormGroup>
                    <Grid xs={12} container spacing={4} >
                        <Box component="span" m={1}>
                            <CardMedia height="300" component={this.state.previewImage==null?"iframe":"img"} image={this.state.previewImage} alt="imagen"/>
                        </Box>
                        <Grid item xs={12}>
                            <Button variant="contained" component="label"> <Input type="file" accept="image/png, image/jpeg" onChange={this.onFileChange} /></Button>
                            <Button onClick={this.onFileUpload}>Upload!</Button> 
                        </Grid>
                        
                        <ImageList rowHeight={160} cols={3}>
                            {this.state.filestorage.map((item) => (
                              <ImageListItem key={item.img} cols={item.cols || 1}>
                                <img src={item.type=="video/mp4"?PlayImage:`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file/${item.id}`} alt={item.title}/>
                                
                                <Button style={{zIndex: '5', position: 'absolute', top: 0, left: 0, width: '48%', height: '40px' }} 
                                  onClick={() => this.remove(item)} size="sm" color="danger" >Eliminar</Button>
                                <Button style={{zIndex: '5', position: 'absolute', top: 0, right: 0, width: '48%', height: '40px' }} 
                                  onClick={() => this.openDialogLink(item)} size="sm" color="danger" >Enlace</Button>
                              </ImageListItem>
                            ))}
                        </ImageList>                        
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                </Container>}
                
                <Dialog fullWidth={true}  open={this.state.openedDialogLink} onClose={() => this.handleCloseDialogLink()} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                  <DialogTitle >
                    <center>
                      <img style={{width: '100%'}} src={item.type=="video/mp4"?PlayImage:`${process.env.REACT_APP_BASE_URL}/v1/file-storage/file/${this.state.linkItem.id}`} alt={item.title}/>
                    </center>
                  </DialogTitle>
                  <DialogContent >
                      <TextField label="Link" fullWidth xs={12} type="text" name="link" id="link" value={this.state.linkItem.link || ''} onChange={this.handleLinkChange}  variant="filled"/>                   
                  </DialogContent>
                  <DialogActions >
                    <Button onClick={() => this.updateLink()} variant="contained" color="primary">OK</Button>                    
                    <Button onClick={() => this.handleCloseDialogLink()} variant="contained" color="primary">Cerrar</Button>
                  </DialogActions>
                </Dialog>
        </div>
    }
}

export default withRouter(EditSystemConfig);