import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import React, { Component, useState, useEffect, useCallback }  from 'react';
import { HashRouter as Router, Route, Switch,  Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@material-ui/icons/Tune';
import PlaceIcon from '@material-ui/icons/Place';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CssBaseline from '@material-ui/core/CssBaseline';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import TabIcon from '@material-ui/icons/Tab';

import HomePage from './pages/HomePage';
import UserPage from './pages/UserPage';

import { AppBar, Badge, IconButton, MenuItem, Toolbar, Typography, Button, Menu, Icon } from '@material-ui/core';
import ListaPreferencias from './pages/preferencias/listapreferencias';
import ListTouristSpot from './pages/TouristSpot/ListTouristSpot';
import ListUser from './pages/Users/ListUser';
import EditarPreferencia from './pages/preferencias/editarpreferencia';
import { AuthService } from './services/AuthService';
import { useHistory } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import EditTouristSpot from './pages/TouristSpot/EditTouristSpot';
import EditTouristSpotValues from './pages/TouristSpotValues/EditTouristSpotValues';
import EditTouristSpotImages from './pages/TouristSpotImages/EditTouristSpotImages';
import EditTouristSpotTabs from './pages/TouristSpotTabs/EditTouristSpotTabs';
import EditUser from './pages/Users/EditUser';
import ListSystemConfig from './pages/SystemConfig/ListSystemConfig';
import EditSystemConfig from './pages/SystemConfig/EditSystemConfig';
import ListDistrict from './pages/SystemConfig/District/ListDistrict';
import EditDistrict from './pages/SystemConfig/District/EditDistrict';
import ListTab from './pages/SystemConfig/Tab/ListTab';
import EditTab from './pages/SystemConfig/Tab/EditTab';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  photo: {
    height: 150,
    width: 'auto'
  },
  fixedHeight: {
    height: 240,
  },
}));

export const mainListItems = (
  <div>
    <ListItem component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Arequipa Map" />
    </ListItem>
    <ListItem button component={Link} to="/admin/preferences">
      <ListItemIcon>
        <TuneIcon />
      </ListItemIcon>
      <ListItemText primary="Preferencias" />
    </ListItem>
    <ListItem button component={Link} to="/admin/tourists">
      <ListItemIcon>
        <PlaceIcon />
      </ListItemIcon>
      <ListItemText primary="L. Turisticos" />
    </ListItem>
    <ListItem button component={Link} to="/admin/users">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Usuarios" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset></ListSubheader>
    <ListItem button component={Link} to="/admin/config">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Configuraciones" />
    </ListItem>
    <ListItem button component={Link} to="/admin/district" >
      <ListItemIcon>
        <LocationCityIcon />
      </ListItemIcon>
      <ListItemText primary="Distritos" />
    </ListItem>
    <ListItem button component={Link} to="/admin/tab" >
      <ListItemIcon>
        <TabIcon />
      </ListItemIcon>
      <ListItemText primary="Tabs" />
    </ListItem>
  </div>
);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function SwitchRouts() {
  return (
    <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/userpage">
              <UserPage />
            </Route>
            <Route exact path="/admin/preferences">
              <ListaPreferencias />
            </Route>
            <Route path='/admin/preferences/:id' component={EditarPreferencia}/>
            <Route exact path="/admin/users">
              <ListUser/>
            </Route>  
            <Route exact path="/admin/tourists">
              <ListTouristSpot />
            </Route>
            <Route path='/admin/tourists/:id' component={EditTouristSpot}/>
            <Route path='/admin/touristsvalues/:id' component={EditTouristSpotValues}/>
            <Route path='/admin/users/:id' component={EditUser}/>
            <Route path='/admin/touristsimages/:id' component={EditTouristSpotImages}/>
            <Route path='/admin/touristsspottab/:id' component={EditTouristSpotTabs}/>
            <Route exact path="/admin/config">
              <ListSystemConfig />
            </Route>
            <Route path='/admin/config/:id' component={EditSystemConfig}/>
            <Route exact path="/admin/district">
              <ListDistrict />
            </Route>
            <Route path='/admin/district/:id' component={EditDistrict}/>
            <Route exact path="/admin/tab">
              <ListTab />
            </Route>
            <Route path='/admin/tab/:id' component={EditTab}/>
      </Switch>
  );
}

export default function Admin () {

  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [openMenu, setOpenMenu] = React.useState(null);
  const [usuario, setUsuario] = React.useState({username:'-'});
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClickMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleLogout = () => {
    const authservice = AuthService;
    authservice.logout();
    history.push("/login");
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  
  useEffect(() => {
    const authservice = AuthService;
    const currentUser = authservice.getCurrentUser();
    if(currentUser && currentUser.accessToken!=null){
      setUsuario(currentUser);
      //window.location.reload();
    }else{
      history.push("/login");
    }
  },[]);
  
  return (
    <div className={classes.root}>
      <Router>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            App Turismo
          </Typography>
          <IconButton color="inherit"  >
          </IconButton>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickMenu} endIcon={<ArrowDownIcon/>}> 
            {usuario.username}
          </Button>
          <StyledMenu
            id="simple-menu"
            anchorEl={openMenu}
            keepMounted
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}  open={open}>
         <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
      
        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>
      <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>          
            <SwitchRouts/>
            <Copyright/>
          </Container>
      </main>
      </Router>
    </div>
      );
  }


  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  