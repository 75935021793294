import { Box, Button, Container, FormGroup, Grid, Paper, Slider, TextField, Typography } from '@material-ui/core';
import React, { Component, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import { AuthService } from '../../services/AuthService';
import Autocomplete from '@material-ui/lab/Autocomplete';

class EditTouristSpot extends Component {

    emptyItem = {
        id: 0,
        average_value: '',
        description: 0,
        latitude:0.00,
        longitude:0.00,
        title:'',
        district:{
            id:'', name:'', description:''
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            districts: [],
            selected:{
                id:'', name:'', description:''
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {//oncreate
        const distritos = await (await fetch(`${process.env.REACT_APP_BASE_URL}/v1/districts`)).json();
        this.setState({districts: distritos});
        
        if (this.props.match.params.id !== 'new') {
            const client = await (await fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots/${this.props.match.params.id}`)).json();
            this.setState({item: client, selected: client.district});
        }
        
    }

    handleSetDistrict(event) {
        const target = event.target;
        const value = target.value;
        
        let item = {...this.state.item};
        console.log(value);
        item["idDistrict"] = value.id;
        this.setState({item});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const favorite = target.favorite;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    handleSliderChange = (event, newValue) => {
        const target = event.target;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = newValue;
        this.setState({item});
    };

    handleDistrictChange = (event, newValue) => {
      const target = event.target;
      const name = target.name;
      let item = {...this.state.item};
      item['district'] = newValue;
      this.setState({item});
  };

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;
        const authservice = AuthService;
        const currentUser = authservice.getCurrentUser;
        let resStatus = 0;
        await fetch(`${process.env.REACT_APP_BASE_URL}/v1/tourist-spots/tourist-spot` + (item.id ? '/' + item.id : ''), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${currentUser.tokenType} ${currentUser.accessToken}`
            },
            body: JSON.stringify(item),
        }).then(res => {
            resStatus = res.status
            return res.json()
          }
        ).then(response => {
            switch (resStatus) {
              case 200,201:
                console.log('success');
                this.props.history.push('/admin/tourists');
                //window.location.reload();
                break
              case 400:
                if (response.code === 'ValidationFailed') {
                  // My custom error messages from the API.
                  console.log(response.fieldMessages)
                } else {
                  console.log('this is a client (probably invalid JSON) error, but also might be a server error (bad JSON parsing/validation)')
                }
                break
              case 500:
                console.log('error');
                console.log(response);

              default:
                console.log('unhandled')
                break
            }
          }
        );
    }

    render() {
        const {item} = this.state;
        const title = <h2>{item.id ? 'Editar Turismo' : 'Registrar Turismo'}</h2>;
        return <div>
            <Container maxWidth="sm">
                {title}
                <form fullWidth onSubmit={this.handleSubmit}>
                    <FormGroup ></FormGroup>
                    <Grid xs={12} container spacing={4} >
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} label="Lugar Turistico" type="text" name="title" id="title" value={item.title || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}   >
                            
                            <Autocomplete
                                id="Distritos"
                                value={item.district} 
                                options={this.state.districts}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 300 }}
                                onChange={this.handleDistrictChange}
                                renderInput={(params) =>
                                    <TextField {...params} label="Seleccione un distrito" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={12}   >
                            <TextField fullWidth xs={12} multiline rows={4} label="Descripción" type="text" name="description" id="description" value={item.description || ''} onChange={this.handleChange}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField   label="Latitud" type="number" name="latitude" id="latitude" value={item.latitude || 0} onChange={this.handleChange} variant="outlined"/>
                            
                            <TextField  label="Longitud" type="number" name="longitude" id="longitude" value={item.longitude || 0} onChange={this.handleChange} variant="outlined"/>
           
                        </Grid>
                        <Grid item xs={12} >
                            <TextField   label="Favorito" type="number" name="favorite" id="favorite" value={item.favorite || ''} onChange={this.handleChange} variant="outlined"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" type="submit">Save</Button>{' '}
                            <Button variant="contained" color="secondary" component={Link} to="/admin/tourists">Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    }
}

export default withRouter(EditTouristSpot);