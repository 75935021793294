import {
  BrowserRouter as Router,
  Redirect,
  useHistory,
  useLocation,
  withRouter
} from "react-router-dom";
import React, { useContext, createContext, useState, Component } from "react";
import { Box, Button, Dialog, Grid, Paper, TextField, Typography, DialogTitle,DialogContent, DialogContentText,DialogActions} from "@material-ui/core";
import { spacing } from '@material-ui/system';
import { AuthService } from "./services/AuthService";

class Login extends Component {
  
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      open:false,
    };
  }

  async componentDidMount() {//oncreate    
    const authservice = AuthService;
    const currentUser = authservice.getCurrentUser();
    if(currentUser && currentUser.accessToken!=null){
      this.props.history.push("/admin");
      //window.location.reload();
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }
  
  handleLogin(event){
    event.preventDefault();

    const authservice = AuthService;
    authservice.login(this.state.username, this.state.password).then(
      () => {
        this.props.history.push("/admin");
        //window.location.reload();
      },
      error => {
        console.log('Error View',error);

        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage,
          open: true
        });
      }
    ).catch(error=>{
      console.log(error);
    });
  }

  handleClose = () => {
    this.setState({open:false});
  };
    

  render() {
    return (
    <div>
    <Grid container justify="center" >
      <Grid item lg={3} md={4} sm={6} xs={11} >
          <Box m={3} />
          <Paper padding={4} variant="elevation" elevation={5}  >
            <Grid container justify="center" spacing={4} >
              <Grid item xs={11} >
                <Typography component="h1" variant="h5">Acceso de Usuarios </Typography>
              </Grid>
              <Grid item xs={11}>
                <form onSubmit={this.handleSubmit}>
                  <Grid container direction="column" spacing={2} alignContent="center"  >
                    <Grid item style={{width:'90%'}}>
                      <TextField fullWidth type="text" placeholder="Usuario" name="username" variant="outlined" value={this.state.username}
                      onChange={this.onChangeUsername} 
                      required autoFocus
                      />
                    </Grid>
                    <Grid item>
                      <TextField fullWidth type="password" placeholder="Password" name="password" variant="outlined" value={this.state.password}
                        onChange={this.onChangePassword} required />
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit" className="button-block" onClick={this.handleLogin} >Submit</Button>
                    </Grid>
                  </Grid>
                </form>
                </Grid>
              <Grid item>
              </Grid>
            </Grid>
          </Paper>
      </Grid>
    </Grid>
    <Dialog open={this.state.open} onClose={this.handleClose}
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.state.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}
export default withRouter(Login);