import { Button } from '@material-ui/core';
import React from 'react';

import { Link } from 'react-router-dom';

export default function HomePage() {

  return (

    <div className="container">

      <h1>Home </h1>
      <Button variant="contained" color="primary">Hola Mundo</Button>
      <p>

        <Link to="/your desired link">Your desired link.</Link>

      </p>

    </div>

  );

}